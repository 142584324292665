<template>
    <div :class="{ 'input-group': true, 'input-group_error': errors.length }">
        <div>
            <p v-if="label" :class="{ 'input-group__label': true, 'input-group__label_required': required }">{{ label }}</p>

            <p v-if="description" class="input-group__description">{{ description }}</p>

            <p v-else-if="hasDescriptionSlot" class="input-group__description">
                <slot name="description" />
            </p>
        </div>

        <slot />

        <p v-if="errors.length" class="input-group__error">{{ errorMessage(errors[0]) }}</p>
    </div>
</template>

<script>
    export default {
        name: 'InputGroup',
        props: {
            description: String,
            errors: Array,
            label: String,
            required: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            hasDescriptionSlot() {
                return !!this.$slots.description;
            },
        },
        methods: {
            errorMessage(message) {
                return message[0].toUpperCase() + message.slice(1).toLowerCase();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input-group {
        display: flex;
        flex-direction: column;

        &_error::v-deep .input__input {
            border: 1px solid #fe6a6a;
        }
    }

    .input-group__label {
        margin-bottom: 0.5rem;
        color: #c5cce3;

        &,
        &:after {
            font-family: 'Gotham Medium', sans-serif;
            font-size: 1.2rem;
            font-weight: 500;
        }

        &_required:after {
            content: '*';
            margin-left: 0.4rem;
            color: #bf4c4c;
        }
    }

    .input-group__description {
        margin-bottom: 1rem;
        color: #6a7290;
        font-family: 'Gotham Book', sans-serif;
        font-size: 0.9rem;
        font-weight: 325;
        line-height: 1.2;
        overflow-wrap: break-word;
    }

    .input-group__error {
        margin-top: 0.5rem;
        color: #fa6666;
        font-family: 'Gotham Light', sans-serif;
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.2;
    }

    @media screen and (min-width: 22.5em) {
        .input-group__label,
        .input-group__label:after {
            font-size: 1.3rem;
        }

        .input-group__description,
        .input-group__error {
            font-size: 1rem;
        }
    }

    @media screen and (min-width: 30em) {
        .input-group__label,
        .input-group__label:after {
            font-size: 1.4rem;
        }

        .input-group__description,
        .input-group__error {
            font-size: 1.2rem;
        }
    }

    @media screen and (min-width: 120em) {
        .input-group__label,
        .input-group__label:after {
            font-size: 1.6rem;
        }

        .input-group__label {
            margin-bottom: 1rem;
        }

        .input-group__description,
        .input-group__error {
            font-size: 1.4rem;
        }

        .input-group__description {
            margin-bottom: 1.2rem;
        }
    }
</style>
